import React from "react"

import * as styles from "./styles.module.less"

const Section = ({ title, body }) => {
  return (
    <section className={styles.Section}>
      <div className={styles.Wrapper}>
        <div className={styles.Container}>
          <h2 className={styles.Title}>{title}</h2>
          <div className={styles.Block}>{body}</div>
        </div>
      </div>
    </section>
  )
}

export const SectionImg = ({ src, alt, width, height }) => {
  return (
    <div className={styles.Img}>
      <img src={src} alt={alt} width={width} height={height} />
    </div>
  )
}

export default Section
