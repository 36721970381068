import React from "react"
import { TextField, styled } from "@material-ui/core"

const KpInput = styled(TextField)({
  "& .MuiInput-root": {
    marginBottom: "15px",
  },
  "& label.MuiInputLabel-root": {
    color: "#003B71",
    fontFamily: "Gotham",
    fontSize: "clamp(14px, 3.9vw, 16px)",
    fontWeight: 300,
    letterSpacing: "0.1px",
  },
  "& label.Mui-focused": {
    color: "#003B71",
  },
  "& .MuiInput-underline:after": {
    borderBottomWidth: "2px",
    borderBottomColor: "#003B71",
  },
  "& .MuiInput-underline:before": {
    borderBottomWidth: "2px",
    borderBottomColor: "#003B71",
  },
})

const Input = ({
  id,
  label,
  name,
  value,
  onChange,
  menuItems,
  children,
  ...rest
}) => {
  return (
    <KpInput
      id={id}
      name={name}
      value={value}
      label={label}
      onChange={onChange}
      variant="standard"
      size="small"
      fullWidth
      required
      inputProps={{
        maxLength: 255,
      }}
      {...rest}
    >
      {children}
    </KpInput>
  )
}

export default Input
