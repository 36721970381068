import React from "react"

import Logo from "../Logo"

import * as styles from "./styles.module.less"

const Form = ({ name = "contact", onSubmit = () => {}, children, ...rest }) => {
  return (
    <section className={styles.Container}>
      <form
        noValidate
        name={name}
        method="post"
        className={styles.Form}
        data-netlify="true"
        onSubmit={onSubmit}
        {...rest}
      >
        <Logo />
        <h1>Contact Form</h1>

        <h2>All fields required</h2>
        <fieldset>{children}</fieldset>
        <p className={styles.Notice}>
          If your company is outside of Oregon or Washington, please visit{" "}
          <a
            rel="noreferrer"
            href="https://kp.org/occupational-health"
            target="_blank"
          >
            kp.org/occupational-health
          </a>{" "}
          for more information.
        </p>
        <input type="hidden" name="form-name" value={name} />
      </form>
    </section>
  )
}

export default Form
