import React from "react"

import Logo from "../Logo"

import * as styles from "./styles.module.less"

const Success = ({ display = true, onCloseClick = () => null }) => {
  if (!display) {
    return null
  }
  return (
    <section className={styles.Success}>
      <div className={styles.Body}>
        <button className={styles.Close} type="button" onClick={onCloseClick}>
          <svg width={16} height={16} viewBox="0 0 16 16" fill="none">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M15.1213 2.12132L2.12132 15.1213L0 13L13 1.03503e-06L15.1213 2.12132Z"
              fill="#003B71"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M15.1213 2.12132L2.12132 15.1213L0 13L13 1.03503e-06L15.1213 2.12132Z"
              fill="#003B71"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M15.1213 2.12132L2.12132 15.1213L0 13L13 1.03503e-06L15.1213 2.12132Z"
              fill="#003B71"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M2.12132 1.86184e-05L15.1213 13L13 15.1213L1.03503e-06 2.12134L2.12132 1.86184e-05Z"
              fill="#003B71"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M2.12132 1.86184e-05L15.1213 13L13 15.1213L1.03503e-06 2.12134L2.12132 1.86184e-05Z"
              fill="#003B71"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M2.12132 1.86184e-05L15.1213 13L13 15.1213L1.03503e-06 2.12134L2.12132 1.86184e-05Z"
              fill="#003B71"
            />
          </svg>
        </button>

        <Logo />
        <h2>Success!</h2>
        <p>
          A KPOJ representative will
          <br />
          be in touch with you soon.
        </p>
      </div>
    </section>
  )
}

export default Success
