import React, { useReducer, useRef, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import IMask from "imask"
import { BLOCKS, INLINES } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

import { MenuItem } from "@material-ui/core"

import Hero from "../Hero"
import Section, { SectionImg } from "./components/Section"
import Form from "./components/Form"
import Input from "./components/TextInput"
import Button from "./components/Button"
import Instruction from "./components/Instruction"
import PreFooter from "./components/PreFooter"
import Success from "./components/Success"

import * as styles from "./styles.module.less"

const renderOptions = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: ({
      data: {
        target: {
          description,
          file: {
            url,
            details: { image },
          },
        },
      },
    }) => {
      return <SectionImg src={url} alt={description} {...image} />
    },
    [INLINES.HYPERLINK]: (node, children) => {
      const {
        data: { uri },
      } = node
      let attr = {}
      if (uri.match(/^https?:\/\//)) {
        attr = {
          target: "_blank",
          rel: "noreferrer",
        }
      }
      return (
        <a href={uri} {...attr}>
          {children}
        </a>
      )
    },
  },
}

const errors = {
  required: "This field is required",
  tel: "Please enter a telephone number in XXX-XXX-XXXX format",
  email: "Please enter an email address",
  empty: "",
}

const actions = {
  VALIDATE_FIELD: "validate",
  SUBMIT: "submit",
  RESET: "reset",
  VALIDATE_FORM: "validateform",
  SET_VALUE: "setvalue",
}

const fields = {
  fullname: "fullname",
  company: "company",
  size: "size",
  state: "state",
  city: "city",
  email: "email",
  phone: "phone",
}

const initialState = {
  valid: false,
  submitted: false,
  values: {
    [fields.size]: "",
    [fields.state]: "",
  },
  errors: {
    [fields.fullname]: {
      error: false,
      msg: errors.empty,
    },
    [fields.company]: {
      error: false,
      msg: errors.empty,
    },
    [fields.size]: {
      error: false,
      msg: errors.empty,
    },
    [fields.state]: {
      error: false,
      msg: errors.empty,
    },
    [fields.city]: {
      error: false,
      msg: errors.empty,
    },
    [fields.email]: {
      error: false,
      msg: errors.empty,
    },
    [fields.phone]: {
      error: false,
      msg: errors.empty,
    },
  },
}

const reducer = (state, action) => {
  switch (action.type) {
    case actions.VALIDATE_FIELD:
      return {
        ...state,
        errors: {
          ...state.errors,
          ...action.value,
        },
      }
    case actions.SUBMIT:
      return {
        ...initialState,
        submitted: action.value,
      }
    case actions.VALIDATE_FORM:
      return {
        ...state,
        valid: action.value,
      }
    case actions.RESET:
      return {
        ...state,
        submitted: false,
      }
    case actions.SET_VALUE:
    default:
      return {
        ...state,
        values: {
          ...state.values,
          ...action.value,
        },
      }
  }
}

const telMaskOpt = {
  mask: "000-000-0000",
}

const ContactPage = () => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const tel = useRef(null)

  /**
   * Create input mask for telephone input
   * Auto-format for ez entry
   */
  useEffect(() => {
    if (tel && tel.current) {
      let mask = new IMask(tel.current, telMaskOpt)
      return () => mask.destroy()
    }
  })

  const {
    contentfulContactPage: {
      hero: {
        headline,
        heroImage: {
          description: heroAlt,
          file: { url: heroSrc },
        },
      },
      sections,
      preFooter,
    },
  } = useStaticQuery(graphql`
    query {
      contentfulContactPage(slug: { eq: "contact" }) {
        title
        hero {
          headline
          eyebrow
          heroImage {
            file {
              url
            }
            description
          }
        }
        preFooter {
          raw
        }
        sections {
          id
          title
          body {
            raw
            references {
              ... on ContentfulAsset {
                __typename
                contentful_id
                description
                file {
                  url
                  details {
                    image {
                      width
                      height
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const mapSection = (section) => {
    const { id, title, body } = section
    return (
      <Section
        key={id}
        title={title}
        body={renderRichText(body, renderOptions)}
      />
    )
  }

  const handleSubmit = (e) => {
    e.preventDefault() // JS Submit

    let valid = Object.keys(fields)
      .map((field) => {
        let { name, type, validity } = e.target.elements[field]
        return handleValidation(name, type, validity)
      })
      .reduce((valid, isValid, i) => {
        return valid && isValid
      }, true)

    dispatch({ type: actions.VALIDATE_FORM, value: valid })

    if (!valid) {
      return
    }
    // fetch form submit here
    let fd = new FormData(e.target)
    let params = new URLSearchParams(fd).toString()
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: params,
    })
      .then((response) => {
        dispatch({ type: actions.SUBMIT, value: true })
        e.target.reset()
        if (!response.ok) {
          console.log(response.statusText)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const handleValidation = (name, type, validity) => {
    let msg = errors.empty
    if (!validity.valid) {
      msg = validity.valueMissing ? errors.required : errors[type]
    }

    dispatch({
      type: actions.VALIDATE_FIELD,
      value: {
        [name]: {
          error: !validity.valid,
          msg,
        },
      },
    })

    return validity.valid
  }

  const handleSelect = (e) => {
    const { name, value } = e.target
    dispatch({
      type: actions.VALIDATE_FIELD,
      value: {
        [name]: {
          error: !value,
          msg: value ? errors.empty : errors.required,
        },
      },
    })

    dispatch({
      type: actions.SET_VALUE,
      value: {
        [name]: value,
      },
    })
  }

  const handleChange = (e) => {
    const { name, type, validity } = e.target
    handleValidation(name, type, validity)
  }

  const handleCloseClick = (e) => {
    dispatch({
      type: actions.RESET,
      value: true,
    })
  }

  return (
    <div>
      <Hero headline={headline} image={heroSrc} alt={heroAlt} />
      {sections.map(mapSection)}
      <section className={styles.endSection}>
        <div className={styles.wrapper}>
          <div className={styles.endContent}>
            <p>
              Or, if you prefer, just fill out the form below and we’ll get in
              touch with you.
            </p>
          </div>
        </div>
      </section>
      <Success display={state.submitted} onCloseClick={handleCloseClick} />
      <Form onSubmit={handleSubmit}>
        <Input
          id={fields.fullname}
          name={fields.fullname}
          label="First &amp; last name"
          onChange={handleChange}
          error={state.errors.fullname.error}
          helperText={state.errors.fullname.msg}
          margin="normal"
        />
        <Input
          id="company"
          name="company"
          label="Company"
          onChange={handleChange}
          error={state.errors.company.error}
          helperText={state.errors.company.msg}
          margin="normal"
        />
        <Instruction>How many employees work for your company?</Instruction>
        <Input
          id="size"
          name="size"
          label="Select range"
          onChange={handleSelect}
          error={state.errors.size.error}
          helperText={state.errors.size.msg}
          value={state.values.size}
          margin="normal"
          select
        >
          <MenuItem key="1–10" value="1–10">
            1–10
          </MenuItem>
          <MenuItem key="11–50" value="11–50">
            11–50
          </MenuItem>
          <MenuItem key="51–100" value="51–100">
            51–100
          </MenuItem>
          <MenuItem key="101–500" value="101–500">
            101–500
          </MenuItem>
          <MenuItem key="501+" value="501+">
            501+
          </MenuItem>
        </Input>
        <Instruction>Where is your company located?</Instruction>
        <Input
          id="state"
          name="state"
          label="Select state"
          onChange={handleSelect}
          error={state.errors.state.error}
          helperText={state.errors.state.msg}
          value={state.values.state}
          margin="normal"
          select
        >
          <MenuItem key="or" value="Oregon">
            Oregon
          </MenuItem>
          <MenuItem key="wa" value="Washington">
            Washington
          </MenuItem>
        </Input>
        <Input
          id="city"
          name="city"
          label="City"
          onChange={handleChange}
          error={state.errors.city.error}
          helperText={state.errors.city.msg}
          margin="normal"
        />
        <Input
          id="email"
          name="email"
          label="Email address"
          type="email"
          onChange={handleChange}
          error={state.errors.email.error}
          helperText={state.errors.email.msg}
          margin="normal"
        />
        <Input
          id="phone"
          inputRef={tel}
          name="phone"
          label="Phone number"
          title="In format XXX-XXX-XXXX"
          type="tel"
          inputProps={{ pattern: "[0-9]{3}-?[0-9]{3}-?[0-9]{4}" }}
          onChange={handleChange}
          error={state.errors.phone.error}
          helperText={state.errors.phone.msg}
          margin="normal"
        />
        <Button type="submit">Submit</Button>
      </Form>
      <PreFooter>{renderRichText(preFooter, renderOptions)}</PreFooter>
    </div>
  )
}

export default ContactPage
