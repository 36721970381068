import React from "react"

import * as styles from "./styles.module.less"
import logo from "./img/logo.svg"

const Logo = () => (
  <img className={styles.Logo} src={logo} width="53" height="50" alt="" />
)

export default Logo
