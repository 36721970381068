import React from "react"

import * as styles from "./styles.module.less"

const Button = ({ type = "button", onClick = () => {}, children }) => {
  return (
    <button type={type} onClick={onClick} className={styles.Button}>
      {children}
    </button>
  )
}

export default Button
